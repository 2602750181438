'use client'

import { useEffect } from 'react'

import { ErrorPage } from '@/shared/components/ErrorPage'
import type { ErrorWithDigest } from '@/shared/utils/error'
import { logNextError } from '@/shared/utils/error'

export default function Error({ error }: { error: ErrorWithDigest }) {
  useEffect(() => {
    logNextError(error)
  }, [error])

  return <ErrorPage message={error.message} digest={error.digest} />
}
